<template>
  <base-section
    id="k-d-a-section-gap"
    class="secondary"
    space="72"
  />
</template>

<script>
  export default {
    name: 'KDASectionGap',
  }
</script>
